/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import "./theme/variables.scss";
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.content-form{
    max-width: 500px;
    margin: 0px auto;
    padding-top: 20px;
    height: 100%;
}

.lbl-title {
    font-size: 20px;
    margin: 20px auto;
}

.lbl-span {
    font-size: 13px;
    margin: 20px auto;
}



.feedback-button {
    font-size: 12px;
    width: 80%;
    --border-radius: 8px;
    height: auto;
    --padding-bottom: 2.1vh;
    --padding-top: 2.1vh;
    --margin: '11vh 0';
}

ion-item {
    --padding-start: 0px;
    --inner-padding-end: 0px;
    --inner-border-width: 0px;
}

/* .feedback-input {
    --background: #363837;
    background: #363837;
    border-radius: 8px;
    --color: var(--ion-color-light);
    --padding-start: 8px !important;
    font-size: 14px;
} */

.feedback-input {
    /* --background: #363837;
    background: #363837;  */
    background-color: transparent; 
    --color: var(--ion-color-light);
    --padding-start: 2px !important;
    font-size: 16px;
    border-bottom: 1px solid white;
}

.feedback-input:focus {
    --background: #363837;
    background: #363837;    
    --color: var(--ion-color-light);
    --padding-start: 8px !important;
    font-size: 14px;
    border-bottom: 1px solid white;
}

.yoxi_footer_btn{
   color: #42A5F5;
   font-size: 14px;
}

.yoxi_form_btn{
    color: white;
    font-size: 14px;
    width: 140px;
    height: auto;
    border: 1px solid #1976D2;
    border-radius: 4px;
    background-color: #1976D2;
 }

 .yoxi_add_btn{
    position: fixed;
    bottom: 5%;
    right: 8%;
    background-color: #1976D2; 
 }

 .yoxi_title{
    color: white;
    font-size: 20px;
    font-weight: normal;
    font-family: Roboto, sans-serif;    
 }



ion-header {
    ion-toolbar{
        --background: #1976D2;
    }
    ion-button{
        --background: none;
        ion-icon, button{
            font-size: 20px;
            color: #fff;
        }
    }
    ion-menu-button{
        color: #fff;
        font-size: 20px;
    }
}

ion-footer {
    ion-toolbar{
        --background: transparent;

    }
}
ion-button {
    --background: var(--ion-color-button);
    --color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    
}

ion-button.button-center{
   
    width: max-content;
    display: block;
    margin: auto;
    margin-top: 15px;
    --padding-start: 30px;
    --padding-end: 30px;
}

/* .login-alert-error{
    .mat-snack-bar-handset .mat-snack-bar-container {
        background-color: #4CAF50 !important;
      }
      
      .mat-simple-snackbar-action button {
        color: purple;
      }
      
      .mat-snack-bar-container {
        background: purple !important;
      }

    } */
     
      
// Material snackbar styles

  snack-bar-container.mat-snack-bar-container.login-alert-error {
    color: white !important;
    background-color: #FF5252 !important;
    border-color: #FF5252 !important;

    .mat-simple-snackbar-action button {
        color: white;
      }
  }

  snack-bar-container.mat-snack-bar-container.success {
    color: white !important;
    background-color: #4CAF50 !important;
    border-color: #4CAF50 !important;

    .mat-simple-snackbar-action button {
        color: white;
      }
  }

  snack-bar-container.mat-snack-bar-container.error {
    color: white !important;
    background-color: #FF5252 !important;
    border-color: #FF5252 !important;

    .mat-simple-snackbar-action button {
        color: white;
      }
  }

  // Mobile resolution styles


@media screen and (max-width: 767px) {
    .content-form{
        margin: 0px 10% ;
    }
}

.btn_grey_bottom{
    width: 120px;
    margin: auto;
    position: fixed;
    bottom: 60px;
    left: 0px;
    right: 0px;
    height: 40px;
    background-color: #1976D2;
    border-radius: 4px;
    
}

.btn_dander_bottom{
    width: 120px;
    margin: auto;
    position: fixed;
    bottom: 60px;
    left: 0px;
    right: 0px;
    height: 40px;    
    background-color: #ff3535;
    --background: #ff3535;
    border-radius: 4px;    
}

.btn_start{
    width: 30px;
    height: 30px;
    font-size: 30px;
    bottom: 60px;
    left: 0px;
    right: 0px;
    margin: auto;
    position: fixed;
    background: none;
    padding: 0px;
}

.btn_start:disabled{
    ion-icon{
        opacity: 0.2;
    }
}

.sliderColorfullVisible {
    ion-range {
        --bar-background-active: transparent;
        --bar-height: 5px;
        --bar-border-radius: 8px;
        --knob-background: #000;
        --knob-border: #fff;
        --knob-size: 16px;
        --knob-box-shadow: #fff;
        --pin-background: #ffafcc;
        --pin-color: #fff;
        --bar-background: linear-gradient(270deg, #674EA7 1.39%, #0000FF 20.9%, #6AA84F 40.42%, #FFFF00 59.93%, #FF9900 79.44%, #FF0000 98.96%);

        
    }
        .range-knob {
            border: 2px solid #fff !important;
        }
}

.action-sheet-button{
    color: #fff!important;
}
.action-sheet-group{
    background: #0d0d0d!important;
}

ion-loading {
    --background: none !important;
    --spinner-color: grey !important;
}

//Aalert ------------------
.alert-wrapper{
    border: 1px solid #fff;
    --background: #000;
    .alert-title, .alert-sub-title, .alert-message{
        color: #fff;
    }
    .alert-button-role-cancel{
        opacity: 0.7;
    }
}
.fs_18{
    font-size: 1.125rem;
}
.fs_16{
    font-size: 1rem;
}

.box_select {
    padding: 0px 0px;
    ion-select {
        background: #363837;
        border-radius: 8px;
        width: 100%;
        color: #fff !important;
        padding-left: 10px;
        padding-right: 10px;
    }
}
ion-modal.modal-filter {
    --width: 80%;
    --min-width: 80%;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

    .ion-page{
        height: 370px!important;
        border: 1px solid #fff;
        border-radius: 6px;
    }
}

.btn-error {
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 15px !important;
    background: var(--ion-color-button) !important;
    color: #000 !important;
    border-radius: 10px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;

}

.mat-dialog-container {
    padding: 0 24px 24px 24px !important;
}

.mat-expansion-indicator::after {
       color: #fff;
}

.mat-expansion-panel-header,
.mat-expansion-panel-header:hover,
.mat-expansion-panel-header:focus {
       background-color: #363837;
}

.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel-header.mat-expanded:focus {
    background-color:#363837;
}
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background:#363837;
}

.mat-select-value {
    color: white;
}

.register_user_form {
    margin-top: 20px;
    padding: 0.8rem;
}

ion-modal {
    .ion-page {
        padding: 25px 15px 30px 15px;
    }
}