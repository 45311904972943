
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

@import './customfont.scss';

:root {
  /** Inputs **/
  --ion-color-button-gradient: linear-gradient(270deg, #1CCE43 0%, #8ABF1A 100%);
  --ion-color-button:#1976D2;
  --ion-color-button-disable: gradient(270deg, #FFFFFF 0%, #C0C0C0 100%);
  --ion-color-anchor: #2175FC;
  

  /** primary **/
  --ion-color-primary: #1CCE43;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1CCE43;
  --ion-color-primary-tint: #1CCE43;

  /* Yoxiv3 Color */
  --ion-color-yoxiblue: #1976D2;
  --ion-color-yoxiblue-rgb: 56, 128, 255;
  --ion-color-yoxiblue-contrast: #1976D2;
  --ion-color-yoxiblue-contrast-rgb: 255, 255, 255;
  --ion-color-yoxiblue-shade: #1CCE43;
  --ion-color-yoxiblue-tint: #1CCE43;
  

  //**secondary**/
  --ion-color-secondary: #1E1E1E;
  --ion-color-secondary-rgb: 52, 199, 89;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1E1E1E;
  --ion-color-secondary-tint: #1E1E1E;

  /**tertiary **/
  --ion-color-tertiary: #ff3b30;
  --ion-color-tertiary-rgb: 255, 59, 48;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #cf3c4f;
  --ion-color-tertiary-tint: #ed576b;

  /** success **/
  --ion-color-success: #00c518;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ff0000;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-medium: #3c3c4399;
  --ion-color-medium-rgb: rgba(60, 60, 67, 0.6);
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #3c3c4399;
  --ion-color-medium-tint: #3c3c4399;

  --color-gray: rgb(60, 60, 67, 0.6);
  --color-text-gray: rgb(60, 60, 67, 0.9);
  --color-bg-gray: rgba(249, 249, 249, 0.94);
  --color-bg-gray-input: rgba(120, 120, 128, 0.32);
  --color-black: #000000;
  --color-draft:  rgba(209, 213, 219, 0.94);
  --color-orange: #ff6a30;

  --color-gray-line: #c6c6c8;

  --ion-background-color: #424242;


    /** fonts **/
    /* --font-sf-pro-semibold: 'SF-Pro-Semibold';
    --font-sf-pro-regular: 'SF-Pro-Regular';
    --font-sf-pro-medium: 'SF-Pro-Medium';
    --font-sf-pro-bold: 'SF-Pro-Bold';
    --font-sf-pro-light: 'SF-Pro-Light';
  
    --ion-font-family: var(--font-sf-pro-regular);
    font-size: 1rem; //16px */


}

.ion-color-button {
  --ion-color-base: var(--ion-color-button) !important;
}

.ion-color-button-gradient {
  --ion-color-base: var(--ion-color-button-gradient)
}

.ion-color-anchor {
  --ion-color-base: var(--ion-color-anchor)
}

/* .sf-pro-regular,
.sf-pro-regular *:not(ion-icon) {
  font-family: var(--font-sf-pro-regular) !important;
  font-style: normal;
  font-weight: normal;
}
.sf-pro-medium,
.sf-pro-medium *:not(ion-icon) {
  font-family: var(--font-sf-pro-medium);
  font-style: normal;
  font-weight: 500;
}
.sf-pro-bold,
.sf-pro-bold *:not(ion-icon) {
  font-family: var(--font-sf-pro-bold);
  font-style: normal;
  font-weight: 700;
}
.sf-pro-semibold,
.sf-pro-semibold *:not(ion-icon) {
  font-family: var(--font-sf-pro-semibold);
  font-style: normal;
  font-weight: 700;
}
.sf-pro-light,
.sf-pro-light *:not(ion-icon) {
  font-family: var(--font-sf-pro-light);
  font-style: normal;
  font-weight: 700;
} */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
